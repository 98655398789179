import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { getDirectionsUrl } from '@helpers/google.helper';
import { PlaceEdge } from 'types';

import Facebook from '@assets/svg/facebook.svg';
import Instagram from '@assets/svg/instagram.svg';
import Linkedin from '@assets/svg/linkedin.svg';
import Close from '@assets/svg/close.svg';

import s from './Info.module.scss';

interface IInfo {
  place: PlaceEdge;
  navigateButtonContent: string;
  callButtonContent: string;
  isDesktop?: boolean;
  closeTooltip?: () => void;
}

const Info: FC<IInfo> = ({ place, navigateButtonContent, callButtonContent, closeTooltip, isDesktop = false }) => {
  const {
    node: {
      placeData: {
        locName,
        locAddress,
        locPhone,
        locLocation: {
          latitude,
          longitude,
        },
        locUrlstore,
        locUrlfacebook,
        locUrlinstagram,
        locUrllinkedin,
      },
    },
  } = place;
  const intl = useIntl();
  const [phone, setPhone] = useState<null | string>(null);

  const directionsUrl = getDirectionsUrl(latitude, longitude);

  useEffect(() => {
    const encodedPhone = locPhone;
    setPhone(encodedPhone);
  }, []);

  return (
    <div className={`${s.info} ${isDesktop ? s.desktop : s.mobile}`}>
      <div className={s.info__close} onClick={closeTooltip}><Close className={s.info__closeSvg} /></div>
      <div className={s.info__row}>
        <div className={`${s.info__name} ${s.info__text}`}>{locName}</div>
        {phone ? `tel. ${phone}` : ''}
      </div>
      <div className={s.info__row}>
        <p className={s.info__text}>
          {locAddress}
        </p>
      </div>
      <div className={s.info__more}>
        <div className={s.info__buttons}>
          <a href={directionsUrl} hrefLang={intl.locale} target={'_blank'} rel="noopener noreferrer" aria-label={navigateButtonContent}>
            <div className={s.info__button}>{navigateButtonContent}</div>
          </a>
          {
            locUrlstore
              ? <a href={locUrlstore} hrefLang={intl.locale} target={'_blank'} rel="noopener noreferrer" aria-label={'www'}>
                <div className={s.info__button}>www</div>
              </a>
              : ''
          }
          {
            phone
              ? <a 
                href={`tel:${phone}`} 
                hrefLang={intl.locale} 
                rel="noopener noreferrer"
                aria-label={callButtonContent}
              >
                <div className={s.info__button}>{callButtonContent}</div>
              </a>
              : ''
          }
        </div>
        <div className={s.info__buttons}>
          {
            locUrlfacebook
              ? <a 
                href={locUrlfacebook} 
                hrefLang={intl.locale} 
                className={s.info__icon} 
                target={'_blank'}
                rel="noopener noreferrer"
                aria-label={'Facebook icon'}
              >
                <Facebook className={s.info__iconSvg} />
              </a>
              : ''
          }
          {
            locUrlinstagram
              ? <a 
                href={locUrlinstagram} 
                hrefLang={intl.locale} 
                className={s.info__icon} 
                target={'_blank'}
                rel="noopener noreferrer"
                aria-label={'Instagram icon'}
              >
                <Instagram className={s.info__iconSvg} />
              </a>
              : ''
          }
          {
            locUrllinkedin
              ? <a 
                href={locUrllinkedin}
                hrefLang={intl.locale}
                className={s.info__icon} 
                target={'_blank'} 
                rel="noopener noreferrer"
                aria-label={'LinkedIn icon'}
              >
                <Linkedin className={s.info__iconSvg} />
              </a>
              : ''
          }
        </div>
      </div>
    </div>
  );
};

export default Info;
